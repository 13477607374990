//----------------------------------------------
// Imports
//----------------------------------------------
@import "unisporkal-styles/stylesheets/unisporkal_engine/istock/_media_breakpoints.scss";
@import "unisporkal-styles/stylesheets/unisporkal_engine/istock/_media";
@import "unisporkal-styles/stylesheets/unisporkal_engine/istock/_config";

.container {
  width: 100%;
  flex-direction: column;
}

.holder {
  align-items: center;
  background-color: $white;
  display: flex;
  flex-direction: row;
  height: 100%;
  width: 100%;
}

.overlay {
  width: 100%;
  height: 100%;
  opacity: 50%;
  background: black;
  position: fixed;
  top: 0;
  left: 0;
}

.verticalRule {
  height: calc(100% - 20px);
  border-left: solid 1px $some-grey-border;
  margin-right: 10px;
}
