//----------------------------------------------
// Imports
//----------------------------------------------
@import "unisporkal-styles/stylesheets/unisporkal/getty/config/_media_breakpoints";
@import "unisporkal-styles/stylesheets/unisporkal/getty/config/_animation";
@import "unisporkal-styles/stylesheets/unisporkal/getty/config/_colors";

.container {
  flex-grow: 1;
  display: flex;
  justify-content: center;
  height: 61px;
}

.content {
  display: flex;
  flex-direction: row;

  width: 100%;
  max-width: 100%;
  height: 61px;

  align-self: center;
  transition: max-width $complex-duration ease;
}

.docked {
  transition: top $complex-duration ease,
              margin-top $complex-duration ease;
  position: fixed;

  top: 0px;

  border-right: none;
  border-left: none;

  @media #{$medium-up} {
    top: 30px;
    max-width: 90%;

    border-right: 1px solid black;
    border-left: 1px solid black;
  }
}

.up {
  margin-top: -152px;

  @media #{$medium-up} {
    margin-top: -95px;
  }
}
