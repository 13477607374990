//----------------------------------------------
// Imports
//----------------------------------------------
@import "unisporkal-styles/stylesheets/unisporkal_engine/istock/_media_breakpoints";
@import "unisporkal-styles/stylesheets/unisporkal_engine/istock/_config";

.container {
  flex-grow: 1;
  position: relative;
  z-index: 30;
  height: 55px;
  @media #{$istock-medium-up} {
    height: 60px;
  }
}

.content {
  display: flex;
  flex-direction: row;

  width: 100%;
  height: 55px;
  @media #{$istock-medium-up} {
    height: 60px;
  }

  border-bottom: 1px solid $some-grey-border;
}

.docked {
  margin: 0 auto;
  max-width: 100%;
  position: fixed;
  top: 0px;
  left: 0px;
  transition: margin-top .1s ease-out, max-width .1s ease-in-out;
}

.up {
  margin-top: -55px;

  @media #{$istock-medium-up} {
    margin-top: -60px;
  }
}
