//----------------------------------------------
// Imports
//----------------------------------------------
@import "unisporkal-styles/stylesheets/unisporkal_engine/istock/_media_breakpoints.scss";
@import "unisporkal-styles/stylesheets/unisporkal_engine/istock/_media";
@import "unisporkal-styles/stylesheets/unisporkal_engine/istock/_config";


.container {
  background-color: $white;
  display: block;
  position: relative;
  z-index: 2;
  cursor: pointer;
  font: normal 14px;
  height: 100%;
  list-style: none;

  .iconClosed {
    transform: rotate(90deg);
  }

  .iconOpen {
    transform: rotate(270deg);
  }

  .iconClosed, .iconOpen {
    position: relative;
    height: 12px;
    width: 12px;
    top: 2px;
    margin-left: 8px;
  }

  @media #{$istock-small-up} {
    display: block;
  }

  .mainLabel {
    border-right: none;
    padding-left: 15px;
    justify-content: flex-start;

    svg {
      margin-right: 10px;

      &.iconOpen, &.iconClosed {
        margin-left: auto;
      }
    }
  }

  .selectedLabel {
    display: none;
    margin-right: 5px;
    
    @media #{$istock-medium-up} {
      display: block;
    }
  }

  .labelIcon {
    @media #{$istock-medium-up} {
      display: block
    }
  }

  label {
    cursor: pointer;
    font-size: .875rem;
    padding: 0 30px;
  }

  .mediaOptions {
    background-color: $white;
    max-height: 0;
    overflow: hidden;
    padding: 0;
    position: absolute;
    transition: max-height 250ms cubic-bezier(0.53, 0, 0.35, 1);
    left: 0;
    top: 54px;

    &.open {
      max-height: 230px;
      width: auto;
    }

    @media #{$istock-medium-up} {
      min-width: 170px;
      position: absolute;
      top: 59px;
    }

    label {
      display: flex;
      align-items: center;
      padding: 0 15px;
    }

    .iconCheckmark {
      margin-top: 3px;
      margin-left: 5px;
      position: relative;
    }

    .dropdownLabel > span {
      padding-left: 0;
    }

    ul {
      border: 1px solid $some-grey-border;

      li {
        border-top: none;
        margin: 0;

        &:hover {
          color: $green;
          background-color: $bg-dark-grey;
        }
      }
    }

    &.imageFilmGridLabels {
      li {
        label.dropdownLabel {
          padding-left: 45px;
        }
      }

      li:first-child {
        label.dropdownLabel {
          padding-left: 15px;
        }
      }

      li:nth-child(2) {
        label.dropdownLabel {
          padding-left: 30px;
        }
      }

      li:last-child {
        label.dropdownLabel {
          padding-left: 30px;
        }
      }
    }

    &.imageFilmGridMenuHeight {
      max-height: 270px;
    }
  }

  ul {
    margin-bottom: 0;
    border: 1px solid $some-grey-border;

    li {
      border-top: 1px solid $some-grey-border;
      line-height: 43px;
      margin: 0 8px;
      transition: all 0.25s ease-in-out;
      white-space: nowrap;

      label.dropdownLabel {
        padding-left: 30px;

        > span {
          padding-left: 30px;
        }
      }

      &:hover {
        color: $green;
      }
    }

    li:first-child {
      border: none;
      label.dropdownLabel {
        padding-left: 15px;
      }
    }

    li:last-child {
      label.dropdownLabel {
        padding-left: 15px;
      }
    }
  }
}

.selectedIconGroup {
  display: flex;
  align-items: center;
}

.mainLabel {
  align-items: center;
  border-left: 1px solid $some-grey-border;
  display: flex;
  font-size: 1rem;
  height: 54px;
  justify-content: center;
  padding: 0 10px;
  text-align: center;
  white-space: nowrap;

  @media #{$istock-medium-up} {
    height: 59px;
  }
}

.iconCheckmark {
  fill: $green;
  display: inline-block;
  height: 13px;
  position: absolute;
  margin-left: 0;
  margin-top: 17px;
  width: 13px;
  user-select: none;
}

.labelIcon {
  margin-right: 10px;
  flex-shrink: 0;
}
