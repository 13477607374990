@import 'unisporkal-styles/stylesheets/unisporkal_engine/istock/_config';
@import 'unisporkal-styles/stylesheets/unisporkal_engine/istock/_media_breakpoints';

.collapsibleSearchBar__container {
  background-color: $white;
}

.collapsibleSearchBar {
  transition: all 0.25s ease-in;
}

.collapsibleSearchBar__collapsed {
  transform: scaleY(0);
  height: 0;
}

.collapsibleSearchBar__expanded {
  position: relative;
  transform: scaleY(1);
  height: 55px;
  z-index: 1;

  @media #{$istock-medium-up} {
    height: 60px;
  }
}
