//----------------------------------------------
// Imports
//----------------------------------------------
@import "unisporkal-styles/stylesheets/unisporkal_engine/istock/_media_breakpoints.scss";
@import "unisporkal-styles/stylesheets/unisporkal_engine/istock/_media";
@import "unisporkal-styles/stylesheets/unisporkal_engine/istock/_config";


.container {
  background-color: $white;
  position: relative;
  display: none;
  cursor: pointer;
  font: normal 14px;
  height: 100%;
  list-style: none;
  border-top: 1px solid $some-grey-border;
  padding: 13px 0px 0px 0px;

  @media #{$medium-up} {
    display: none;
  }

  .iconClosed {
    transform: rotate(90deg);
  }

  .iconOpen {
    transform: rotate(270deg);
  }

  .iconClosed, .iconOpen {
    margin-left: auto;
    margin-right: 20px;
    position: relative;
    height: 12px;
    width: 12px;
    top: 2px;
  }

  label {
    cursor: pointer;
    font-size: .875rem;
    padding: 0 55px;
    position: relative;
    display: flex;
    align-items: center;
  }

  .mediaOptions {
    position: relative;

    background-color: $white;
    border-top: none;
    border-bottom: none;
    max-height: 0;
    overflow: hidden;
    transition: max-height 250ms cubic-bezier(0.53, 0, 0.35, 1);
    text-transform: capitalize;

    &.open {
      border-top: 1px solid $some-grey-border;
      max-height: 225px;
    }
  }

  ul {
    margin-bottom: 0;

    li {
      border-top: 1px solid $some-grey-border;
      line-height: 43px;
      padding: 0 0 0 15px;
      transition: all 0.25s ease-in-out;
      white-space: nowrap;

      &:hover {
        color: $green;
      }
    }

    li:first-child {
      border: none;
      padding-left: 0;
    }

    li:last-child {
      padding-left: 0;
    }
  }
}

.mainLabel {
  align-items: center;
  border-left: none;
  display: flex;
  font-size: 1rem;
  height: 36px;
  justify-content: left;
  padding-bottom: 15px;
  padding-left: 32px;
  padding-top: 2px;
  text-align: center;
  text-transform: capitalize;
  white-space: nowrap;
}

.iconCheckmark {
  fill: $green;
  position: absolute;
  width: 13px;
  user-select: none;
  left: 30px;
}
