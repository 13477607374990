//----------------------------------------------
// Imports
//----------------------------------------------
@import "unisporkal-styles/stylesheets/unisporkal/getty/config/_media_breakpoints";
@import "unisporkal-styles/stylesheets/unisporkal/getty/config/_animation";
@import "unisporkal-styles/stylesheets/unisporkal/getty/config/_colors";

.container {
  width: 100%;
  flex-direction: column;
  z-index: 1;
}

.holder {
  border-top: solid 1px $gi-black;
  border-bottom: solid 1px $gi-black;
  display: flex;
  flex-direction: row;
  height: 100%;
  width: 100%;


  @media #{$medium-up} {
    border: solid 1px $gi-black;
  }
}

.overlay {
  width: 100%;
  height: 100%;
  opacity: 50%;
  background: $gi-black;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1;
}

.tooltipWrapper {
  position: relative;
}