//----------------------------------------------
// Imports
//----------------------------------------------
@import 'unisporkal-styles/stylesheets/unisporkal/getty/config/_media_breakpoints';
@import "unisporkal-styles/stylesheets/unisporkal/getty/config/_animation";
@import "unisporkal-styles/stylesheets/unisporkal/getty/config/_colors";

.container {
  background-color: $gi-white;
  cursor: pointer;
  display: none;
  font: normal 14px;
  height: 100%;
  list-style: none;
  padding: 13px 0 11px 0;
  position: relative;
  z-index: 1;

  &SearchbarRefresh {
    display: flex;
  }

  @media #{$medium-up} {
    display: block;
  }

  label {
    cursor: pointer;
    padding-right: 15px;
    padding-left: 15px;
  }

  .mediaOptions {
    position: relative;
    left: 1px;
    top: 11px;

    background-color: $gi-white;
    border: 1px solid $gi-black;
    border-bottom: none;
    max-height: 0;
    overflow: hidden;
    padding: 0 8px;
    transition: max-height 250ms cubic-bezier(0.53, 0, 0.35, 1);

    &.open {
      border-bottom: 1px solid $gi-black;
      display: table;
    }

    .options {
      margin-right: 20px;
      margin-left: 15px;
      margin-top: 15px;
      margin-bottom: 15px;
    }
  }

  ul {
    margin-bottom: 0;
  }
}

.iconContainer {
  text-align: center;
  position: relative;
  padding-left: 10px;

  .iconClosed {
    transform: rotate(90deg);
  }

  .iconOpen {
    transform: rotate(270deg);
  }

  .iconClosed, .iconOpen {
    height: 12px;
    width: 12px;
  }
}

@mixin baseOption {
  line-height: 35px;
  padding: 0 0 0 15px;
  transition: all 0.25s ease-in-out;
  white-space: nowrap;

  .holder {
    display: flex;
    align-items: center;
    margin-right: 5px;
  }

  .iconCheckmark {
    path {
      fill: $purple;
    }
    display: inline-block;
    margin-top: 2px;
    height: 13px;
    position: relative;
    width: 13px;
    user-select: none;
  }

  &:hover {
    color: $primary-color;

    .iconCheckmark {
      path {
        fill: $primary-color
      }
    }
  }
}

.option {
  @include baseOption;
}

.headerOption {
  @include baseOption;
  padding-left: 0;
}

.headerOption:not(:first-child) {
  border-top: 1px solid #ccc;
  margin-top: 10px;
  padding-top: 10px;
}

.mainLabel {
  all: unset;
  border-left: 1px solid $med-grey;
  font-size: 1rem;
  text-align: center;
  white-space: nowrap;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  &:focus {
    outline: none;
  }
}
